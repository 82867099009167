import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Badge, TabBar } from 'antd-mobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBasketShopping, faCircleInfo,faHouse, faUser, faSearch
} from '@fortawesome/free-solid-svg-icons';


import { StoreContext } from '../store';


const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const [state, dispatch] = useContext(StoreContext);


  const setRouteActive = value => {
    navigate(value)
  }

  const tabs = [
    {
      key: '/home',
      title: 'Главная',
      icon: <FontAwesomeIcon icon={faHouse} />,
    },
    {
      key: '/search',
      title: 'Поиск',
      icon: <FontAwesomeIcon icon={faSearch} />,
    },
    {
      key: '/checkout',
      title: 'Корзина',
      icon: <Badge content={state.cart_count || ''} style={{'--top': '5px'}}>
              <FontAwesomeIcon icon={faBasketShopping} />
            </Badge>,
    },
    {
      key: '/profile',
      title: 'Профиль',
      icon: <FontAwesomeIcon icon={faUser} />,
    },
    {
      key: '/about',
      title: 'О нас',
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
    },
  ]

  return (
    <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  )
}

export default NavBar;
