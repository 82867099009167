import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const RoundedIcon = ({icon, onClick}) => {

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      width: '45px',
      borderRadius: '50%',
      backgroundColor: 'white',
    }} onClick={onClick} >
      <FontAwesomeIcon size={'2x'} icon={icon} />
    </div>
  )
}

export default RoundedIcon;
