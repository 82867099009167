import { Divider } from 'antd-mobile';

const PageHeader = ({title}) => {

  return (
    <>
      <div className="page-header">
        {title}
      </div>

      <Divider style={{
        borderColor: 'white'
      }}/>
    </>
  )
}

export default PageHeader;
