import React, { Fragment } from "react";

import NavBar from '../components/navbar';

const DefaultLayout = ({ children }) => {
  return (
    <Fragment>
      <div className='page'>
        {children}
      </div>

      <div className='navbar'>
        <NavBar />
      </div>
    </Fragment>
  );
};

export default DefaultLayout;
