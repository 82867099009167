import { useState } from 'react';

import { Checkbox, Dialog, Button, Card, Grid, Image, Input, Space } from 'antd-mobile';
import { DeleteOutline } from 'antd-mobile-icons';


const BasketItemCard = ({ item, checked, onCheck, onRemoveItem, onChangeQuantity }) => {

  const { quantity, detail_id, detail } = item;

  const [value, setValue] = useState(quantity);

  const onDeleteAction = ({ key }) => {
    if (key === 'delete') {
      onRemoveItem(detail_id);
    }
  }


  const onChange = value => {
    const val = parseInt(value, 10);
    if (val && val <= item.detail.quantity) {
      setValue(value);
      onChangeQuantity(detail_id, val);
    }
  }


  return (
    <Card>
      <div>
        <Grid columns={5} gap={8}>
          <Grid.Item>
            <div className="card-item">
              {/*<Image src={'/images/box.png'} height={50} width={50} />*/}
              <Checkbox checked={checked} onChange={onCheck(detail_id)} />
            </div>
          </Grid.Item>
          <Grid.Item span={4}>
            <Grid columns={1}>

              <Grid.Item>
                <Grid columns={2}>
                  <Grid.Item>
                    <div className="card-item-article">
                      {detail.article}
                    </div>
                  </Grid.Item>

                  <Grid.Item>
                    <div className="card-item-producer">
                      {detail.producer}
                    </div>
                  </Grid.Item>
                </Grid>
              </Grid.Item>

              <Grid.Item>
                <div className="card-item-name">
                  {detail.name}
                </div>
              </Grid.Item>

              <Grid columns={2} gap={8}>
                <Grid.Item>
                </Grid.Item>

                <Grid.Item span={2}>
                  <Grid.Item>
                    <div className="card-item-price">
                      <span className="card-item-price-quantity">
                        {detail.quantity}
                      </span>

                      <span>
                        &nbsp;{`${detail.unit} в наличии`}
                      </span>

                    </div>
                  </Grid.Item>
                </Grid.Item>
              </Grid>

              <Grid columns={2}>
                <Grid.Item>
                  <Input className='input-quantity'
                    type={'number'}
                    value={value}
                    onChange={onChange}
                    min={1}
                    max={1000}
                  />
                </Grid.Item>

                <Grid.Item>
                  <div className="card-item-price">
                    <span className="card-item-price-value">
                      {`${detail.price_9 * quantity}`}
                    </span>
                    <span style={{
                      fontSize: '0.8em'
                    }}>
                      &nbsp;{`₽`}
                    </span>

                  </div>
                </Grid.Item>
              </Grid>

              <Grid columns={2}>
                <Grid.Item>
                  <span style={{
                    fontSize: '1em',
                    textColor: 'gray',
                  }}>
                    {detail.price_9}
                  </span>
                  <span style={{
                    fontSize: '0.8em'
                  }}>
                    &nbsp;{`₽/${detail.unit}`}
                  </span>
                </Grid.Item>

                <Grid.Item>
                  <div className="card-item-price">
                    <Button color='danger' fill='none'
                            onClick={() =>
                              Dialog.show({
                                content: 'Убрать товар из корзины? Отменить действие будет нельзя.',
                                closeOnAction: true,
                                onAction: onDeleteAction,
                                actions: [
                                  [
                                    {
                                      key: 'cancel',
                                      text: 'Отменить',
                                    },
                                    {
                                      key: 'delete',
                                      text: 'Удалить',
                                      danger: true,
                                    },
                                  ],
                                ]
                              })
                            }
                    >
                      <Space>
                        <DeleteOutline />
                        <span>{'Удалить'}</span>
                      </Space>
                    </Button>
                  </div>
                </Grid.Item>
              </Grid>

            </Grid>
          </Grid.Item>
        </Grid>
      </div>
    </Card>
  );
}


export default BasketItemCard;
