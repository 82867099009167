import { useCallback, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';

import { useApolloClient, gql, useMutation } from '@apollo/client';

import { Card, Dialog, Empty, Input, Grid, List, NavBar, SearchBar, Space, SpinLoading } from 'antd-mobile';
import { InformationCircleOutline } from 'antd-mobile-icons';

import ItemCard from '../components/item';
import RoundedIcon from '../components/roundedIcon';

import { useAuth } from '../context';


const CustomSearchBar = ({ onSearch }) => {

  const [query, setQuery] = useState('')

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Space style={{ margin: '1em', textAlign: 'center' }}>
        <SearchBar
          style={{
            '--height': '48px',
            boxSizing: 'border-box',
            marginLeft: '1em',
            marginRight: '1em'
          }}
          onSearch={onSearch}
          onChange={val => setQuery(val)}
          placeholder={'строка поиска'}
        />

        <RoundedIcon icon={faSearch} onClick={() => onSearch(query)} />
      </Space>
    </div>
  );
}


const GET_BASKET_ITEMS = gql`query BasketItems($client_id: String!) {
  basket_mobile(where: {client_id: {_eq: $client_id}}) {
    detail_id
  }
}`;


const GET_SEARCH = gql`query search($search: String, $limit: Int, $offset: Int) {
  details(where: {_or: [{name: {_like: $search}},
                        {article: {_like: $search}}]},
          order_by: {name: asc},
          limit: $limit, offset: $offset) {
    id
    item
    price_9
    quantity
    name
    article
    original_article
    original_werk_article
    producer
    unit
    vendor
    werk_article
  }
}`;


const ADD_ITEM_TO_CART = gql`mutation AddItemToCart($added_at: timestamp!,
                                                    $client_id: String!,
                                                    $detail_id: Int!,
                                                    $item: String!) {
  insert_basket_mobile(objects: {added_at: $added_at,
                                 client_id: $client_id,
                                 detail_id: $detail_id,
                                 item: $item,
                                 quantity: 1}) {
    returning {
      detail_id
      id
      item
      quantity
    }
  }
}`;

const REMOVE_ITEM_FROM_CART = gql`mutation RemoveItemFromCart($client_id: String!,
                                                              $detail_id: Int!) {
  delete_basket_mobile(where: {client_id: {_eq: $client_id}, detail_id: {_eq: $detail_id}}) {
    returning {
      detail_id
      id
      item
      quantity
    }
  }
}`;


const SearchPage = () => {

  const { visitorId } = useAuth();

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const client = useApolloClient();

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [details, setDetails] = useState([]);
  const [basketItems, setBasketItems] = useState([]);

  const [addItemToCart, add_process] = useMutation(ADD_ITEM_TO_CART);
  const [removeItemFromCart, remove_process] = useMutation(REMOVE_ITEM_FROM_CART);


  useEffect(() => {
    if (visitorId) {
      client
        .query({
          query: GET_BASKET_ITEMS,
          variables: {
            client_id: visitorId
          }
        })
        .then(({error, data}) => {
          if (data) {
            console.log(visitorId, data.basket_mobile)
            setBasketItems(data.basket_mobile.map(item => item.detail_id));
          }
        });
    }

  }, []);


  const onSearch = val => {
    if (val.length <= 3) {
      return;
    }

    setDetails(null);

    client
      .query({
        query: GET_SEARCH,
        variables: {
          search: `%${val.toUpperCase()}%`,
          limit,
          offset: 0
        }
      })
      .then(({error, data}) => {
        if (data) {
          setDetails(data.details);
          setOffset(prev => prev + limit);
          setHasMore(data.details.length > 0);
        }
      });
  }

  const onItemAdd = (detail_id, item, action) => {
    console.log(detail_id, item, action)

    if (action === 'add') {
      const added_at = new Date().toISOString();
      addItemToCart({
        variables: {
          added_at,
          client_id: visitorId,
          detail_id, item
        },
      });
    }

    if (action === 'remove') {
      removeItemFromCart({
        variables: {
          client_id: visitorId,
          detail_id
        },
      });
    }
  }

  const back = () => {
    navigate('/home');
  }

  if (details === null) {
    return (
      <>
        <SpinLoading style={{ '--size': '48px' }} />
      </>
    );
  }

  if (details.length === 0) {
    return (
      <>
        <CustomSearchBar onSearch={onSearch} />

        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
        />
      </>
    );
  }

  return (
    <>
      <CustomSearchBar onSearch={onSearch} />

      <div className="card-group">
        <List>
          {details.map((detail, index) => (
            <List.Item key={detail.id} style={{background: '#eeeeee'}}>
              <ItemCard
                key={detail.id}
                item={detail}
                in_cart={basketItems.indexOf(detail.id) !== -1}
                onItemAdd={onItemAdd} />
            </List.Item>
          ))}
        </List>
      </div>
    </>
  )
}


export default SearchPage;
