import { useState } from 'react';

import { Button, Card, Grid, Image } from 'antd-mobile';


const ItemCard = ({ item, in_cart, onItemAdd }) => {

  const [inCart, setInCart] = useState(in_cart);


  const onAction = action => () => {
    setInCart(!inCart);
    onItemAdd(item.id, item.item, action);
  }


  return (
    <Card>
      <div>
        <Grid columns={5} gap={8}>
          <Grid.Item>
            <div className="card-item">
              <Image src={'/images/box.png'} height={50} width={50} />
            </div>
          </Grid.Item>
          <Grid.Item span={4}>
            <Grid columns={1}>

              <Grid.Item>
                <Grid columns={2}>
                  <Grid.Item>
                    <div className="card-item-article">
                      {item.article}
                    </div>
                  </Grid.Item>

                  <Grid.Item>
                    <div className="card-item-producer">
                      {item.producer}
                    </div>
                  </Grid.Item>
                </Grid>
              </Grid.Item>

              <Grid.Item>
                <div className="card-item-name">
                  {item.name}
                </div>
              </Grid.Item>

              <Grid columns={2} gap={8}>
                <Grid.Item>
                  <div className="card-item-button">

                    {inCart
                      ? <Button block shape='rounded' color='warning'
                                onClick={onAction('remove')}>
                          {'Убрать'}
                        </Button>
                      : <Button block shape='rounded' color='primary'
                                onClick={onAction('add')}>
                          {'В корзину'}
                        </Button>
                    }

                  </div>
                </Grid.Item>

                <Grid.Item>
                  <Grid.Item>
                    <div className="card-item-price">
                      <span className="card-item-price-quantity">
                        {item.quantity}
                      </span>

                      <span>
                        &nbsp;{'в наличии'}
                      </span>

                    </div>
                  </Grid.Item>

                  <Grid.Item>
                    <div className="card-item-price">
                      <span className="card-item-price-value">
                        {`${item.price_9}`}
                      </span>
                      <span style={{
                        fontSize: '0.8em'
                      }}>
                        &nbsp;{`₽/${item.unit}`}
                      </span>

                    </div>
                  </Grid.Item>
                </Grid.Item>
              </Grid>

            </Grid>
          </Grid.Item>
        </Grid>
      </div>
    </Card>
  );
}


export default ItemCard;
