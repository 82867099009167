import {
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

import { Grid } from 'antd-mobile';

import RoundedIcon from './roundedIcon';

const HeadBar = ({title, onClick}) => {

  return (
    <div className='headbar'>
      <Grid columns={5} gap={8} >
        <Grid.Item>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <RoundedIcon icon={faChevronLeft} onClick={onClick} />
          </div>

        </Grid.Item>
        <Grid.Item span={4}>
          <div style={{
            height: '100%',
            width: '100%',
            textAlign: 'left',
            verticalAlign: 'middle',
          }}>
            {title}
          </div>
        </Grid.Item>
      </Grid>
    </div>

  )
}

export default HeadBar;
