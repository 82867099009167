import './App.css';

import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import DefaultLayout from './layouts/default';

import HomePage from './pages/home';
// import CategoriesPage from './pages/categories';
import CategoryPage from './pages/category';
import SearchPage from './pages/search';
import CategoryItemsPage from './pages/category_items';
import CheckoutPage from './pages/checkout';
import ProfilePage from './pages/profile';
import AboutPage from './pages/about';

import { useAuth } from './context';


function App() {

  const { signin } = useAuth();

  useEffect(() => {
    FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => signin(result.visitorId));
  }, [signin]);

  return (
      <Router initialEntries={['/home']}>
        <Routes>
          <Route
            path='/' exact
            element={
              <DefaultLayout>
                <HomePage />
              </DefaultLayout>
            }
          />

          <Route
            path='/home' exact
            element={
              <DefaultLayout>
                <HomePage />
              </DefaultLayout>
            }
          />

          {/*<Route
            path='/category' exact
            element={
              <DefaultLayout>
                <CategoryPage />
              </DefaultLayout>
            }
          />*/}

          <Route
            path='/category/:id' exact
            element={
              <DefaultLayout>
                <CategoryPage />
              </DefaultLayout>
            }
          />

          <Route
            path='/category/items/:id' exact
            element={
              <DefaultLayout>
                <CategoryItemsPage />
              </DefaultLayout>
            }
          />

          <Route
            path='/search' exact
            element={
              <DefaultLayout>
                <SearchPage />
              </DefaultLayout>
            }
          />

          <Route
            path='/checkout' exact
            element={
              <DefaultLayout>
                <CheckoutPage />
              </DefaultLayout>
            }
          />

          <Route
            path='/profile' exact
            element={
              <DefaultLayout>
                <ProfilePage />
              </DefaultLayout>
            }
          />

          <Route
            path='/about' exact
            element={
              <DefaultLayout>
                <AboutPage />
              </DefaultLayout>
            }
          />
        </Routes>
      </Router>
  );
}


export default App;
