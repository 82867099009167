import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useApolloClient, gql, useLazyQuery, useMutation } from '@apollo/client';

import { Empty, InfiniteScroll, List, NavBar, SpinLoading } from 'antd-mobile';

import HeadBar from '../components/headbar';
import ItemCard from '../components/item';

import { useAuth } from '../context';
import { StoreContext } from '../store';


const GET_ITEMS_LIST = gql`query MyQuery($id: Int) {
 attributes(where: {visible: {_eq: true}, attribut: {_eq: $id}}) {
    attribut
    add_category
    name
    items
  }
}`;


const GET_BASKET_ITEMS = gql`query BasketItems($client_id: String!) {
  basket_mobile(where: {client_id: {_eq: $client_id}}) {
    detail_id
  }
}`;


const GET_ITEMS = gql`query details($items: [String], $limit: Int, $offset: Int) {
  details(where: {item: {_in: $items}, quantity: {_gt: "0"}}, order_by: {name: asc}, limit: $limit, offset: $offset) {
    id
    item
    price_9
    quantity
    name
    article
    original_article
    original_werk_article
    producer
    unit
    vendor
    werk_article
  }
}`;

const ADD_ITEM_TO_CART = gql`mutation AddItemToCart($added_at: timestamp!,
                                                    $client_id: String!,
                                                    $detail_id: Int!,
                                                    $item: String!) {
  insert_basket_mobile(objects: {added_at: $added_at,
                                 client_id: $client_id,
                                 detail_id: $detail_id,
                                 item: $item,
                                 quantity: 1}) {
    returning {
      detail_id
      id
      item
      quantity
    }
  }
}`;

const REMOVE_ITEM_FROM_CART = gql`mutation RemoveItemFromCart($client_id: String!,
                                                              $detail_id: Int!) {
  delete_basket_mobile(where: {client_id: {_eq: $client_id}, detail_id: {_eq: $detail_id}}) {
    returning {
      detail_id
      id
      item
      quantity
    }
  }
}`;


const CategoryItemsPage = () => {

  const { visitorId } = useAuth();

  const params = useParams();
  const navigate = useNavigate()
  const location = useLocation()

  const client = useApolloClient();

  const [items, setItems] = useState(null);
  const [details, setDetails] = useState([]);
  const [basketItems, setBasketItems] = useState([]);

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [getItemsList, itemsListData] = useLazyQuery(GET_ITEMS_LIST, {
    fetchPolicy: 'network-only',
  });

  const [getItems, itemsData] = useLazyQuery(GET_ITEMS, {
    fetchPolicy: 'network-only',
  });

  const [getBasketItems, basketData] = useLazyQuery(GET_BASKET_ITEMS, {
    fetchPolicy: 'network-only',
  });

  const [addItemToCart, add_process] = useMutation(ADD_ITEM_TO_CART);
  const [removeItemFromCart, remove_process] = useMutation(REMOVE_ITEM_FROM_CART);

  const [state, dispatch] = useContext(StoreContext);


  useEffect(() => {
    getItemsList({
      variables: {
        id: params.id,
      }
    });

    if (visitorId) {
      getBasketItems({
        variables: {
          client_id: visitorId
        }
      });
    }

  }, []);


  useEffect(() => {
    const { data } = itemsListData;
    if (data) {
      setItems(data.attributes[0].items);
    }
  }, [itemsListData]);


  useEffect(() => {
    if (items) {
      getItems({
        variables: {
          items: items.join(','),
          limit,
          offset
        }
      })
    }
  }, [items]);


  useEffect(() => {
    const { data } = itemsData;
    if (data) {
      setDetails(prev => [...prev, ...data.details]);
      setOffset(prev => prev + limit);
      setHasMore(true);
    }
  }, [itemsData]);


  useEffect(() => {
    const { data } = basketData;
    if (data) {
      setBasketItems(data.basket_mobile.map(item => item.detail_id));
      dispatch({type: 'SET_CART_COUNT', cart_count: data.basket_mobile.length});
    }
  }, [basketData]);


  const loadMore = () => {
    setHasMore(false);
    getItems({
      variables: {
        items: items.join(','),
        limit,
        offset
      }
    })
  }


  const onItemAdd = (detail_id, item, action) => {
    if (action === 'add') {
      const added_at = new Date().toISOString();
      addItemToCart({
        variables: {
          added_at,
          client_id: visitorId,
          detail_id, item
        },
        refetchQueries: [
          {
            query: GET_BASKET_ITEMS, variables: {
              client_id: visitorId
            }
          },
        ],
      });
    }

    if (action === 'remove') {
      removeItemFromCart({
        variables: {
          client_id: visitorId,
          detail_id
        },
        refetchQueries: [
          {
            query: GET_BASKET_ITEMS, variables: {
              client_id: visitorId
            }
          },
        ],
      });
    }
  }

  if (!items || !details) {
    return (
      <>
        <HeadBar onClick={() => {navigate('/home');}} />
        <SpinLoading style={{ '--size': '48px' }} />
      </>
    );
  }

  if (details && details.length === 0) {
    return (
      <>
        <HeadBar onClick={() => {navigate('/home');}} />

        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description='Нет товаров'
        />
      </>
    );
  }

  return (
    <>

      <HeadBar onClick={() => {navigate('/home');}} />

      <div className="card-group" >
        <List>
          {details.map((detail, index) => (
            <List.Item key={detail.id} style={{background: '#eeeeee'}}>
              <ItemCard
                item={detail}
                in_cart={basketItems.indexOf(detail.id) !== -1}
                onItemAdd={onItemAdd} />
            </List.Item>
          ))}
        </List>

        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          {'ПОКАЗАТЬ ЕЩЁ'}
        </InfiniteScroll>
      </div>

    </>
  )
}

export default CategoryItemsPage;
