import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Image } from 'antd-mobile';


const shop_groups = [
  { id: 16, title: 'ВАЗ (LADA)', image: 'images/common_auto.png'},
  { id: 26 , title: 'ГАЗ', image: 'images/common_auto.png'},
  { id: 27 , title: 'УАЗ', image: 'images/common_auto.png'},
  { id: 17 , title: 'Иномарки', image: 'images/common_auto.png'},
  { id: 33 , title: 'Автокрепёж', image: 'images/screws.png'},
  { id: 20 , title: 'Масла и химия', image: 'images/auto_oil.png'},
  { id: 32 , title: 'АКБ', image: 'images/battery.png'},
  { id: 30 , title: 'Лампы', image: 'images/bulb.png'},
]


const ShopGroupCard = ({item, onClick}) => {
  return (
    <Card className="card-title" title={item.title} onClick={onClick}>
      <div className="card-title-content">
        <Image src={item.image} />
      </div>
    </Card>
  );
}

function HomePage() {

  // const cardOnClick = ({id}) => () => {
  //   console.log('Click', id);
  // }

  const navigate = useNavigate()
  // const location = useLocation()
  // const { pathname } = location

  const cardOnClick = useCallback((id) => {
    return () => {
      navigate(`/category/${id}`)
    };
  }, [navigate]);

  return (
    <div className='card-group' >
      {shop_groups.map(item => (
          <ShopGroupCard
            key={item.id}
            item={item}
            onClick={cardOnClick(item.id)} />
      ))}
    </div>
  )
}


export default HomePage;
