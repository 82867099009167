import { Empty } from 'antd-mobile';

import PageHeader from '../components/pageHeader';


const ProfilePage = () => {

  return (
    <>
      <PageHeader title={'Заказы'} />

      <Empty
        style={{ padding: '64px 0' }}
        imageStyle={{ width: 128 }}
        description='Нет заказов'
      />
    </>
  );
}


export default ProfilePage;
