import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';

import { Card, Button, Divider, Empty, NavBar, Space, SpinLoading } from 'antd-mobile';

import HeadBar from '../components/headbar';


const CategoryCard = ({ item, onClick }) => {
  return (
    <Card className="card-category" onClick={onClick} >
      <div className="card-category-content">
        {item.name}
      </div>
    </Card>
  );
}


const CategoryPage = () => {

  const params = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const { loading, error, data } = useQuery(gql`
      query category($id: Int) {
        attributes(where: {visible: {_eq: true}, add_category: {_eq: $id}}, order_by: {name: asc}) {
          attribut
          add_category
          name
        }
      }
    `, {
      variables: {
        id: params.id
      }
  });

  const cardOnClick = useCallback((attribut) => {
    return () => {
      navigate(`/category/items/${attribut}`)
    };
  }, [navigate]);

  if (loading || data === undefined) {
    return (
      <>
        <HeadBar onClick={() => {navigate('/home');}} />
        <SpinLoading style={{ '--size': '48px' }} />
      </>
    );
  }

  if (data && data.attributes.length === 0) {
    return (
      <>
        <HeadBar onClick={() => {navigate('/home');}} />

        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description='Нет категорий'
        />
      </>
    );
  }

  return (
    <>
      <HeadBar onClick={() => {navigate('/home');}} />

      <div className="card-group">
        {data.attributes.map(item => (
            <CategoryCard
              key={item.attribut}
              item={item}
              onClick={cardOnClick(item.attribut)} />
        ))}
      </div>

    </>
  )
}

export default CategoryPage;
