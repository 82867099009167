import PageHeader from '../components/pageHeader';

const AboutPage = () => {
  return (
    <>
      <PageHeader title={'О магазине'} />
    </>
  );
}

export default AboutPage;
