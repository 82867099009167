import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';

import { AuthProvider } from './context';
import Store from './store';


// const defaultOptions: DefaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
// }


const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graphql.tehkom-avto.ru/v1/graphql',
    headers: {
      'x-hasura-admin-secret': 'eiM2oiquah9aeR2shiekie9johb5ahpe'
    }
  }),
  cache: new InMemoryCache(),
  // defaultOptions: defaultOptions,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <Store>
        <App />
      </Store>
    </ApolloProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
