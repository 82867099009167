import React, { useContext, useEffect, useState } from 'react';

import { Button, Checkbox, Empty, Grid, List } from 'antd-mobile';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useAuth } from '../context';
import { StoreContext } from '../store';

import PageHeader from '../components/pageHeader';
import BasketItemCard from '../components/basketItem';


const GET_BASKET_ITEMS = gql`query BasketItems($client_id: String!) {
  basket_mobile(where: {client_id: {_eq: $client_id}}) {
    detail_id
    quantity
    detail {
      id
      item
      price_9
      quantity
      name
      article
      original_article
      original_werk_article
      producer
      unit
      vendor
      werk_article
    }
  }
}`;


const REMOVE_ITEM_FROM_CART = gql`mutation RemoveItemFromCart($client_id: String!,
                                                              $detail_id: Int!) {
  delete_basket_mobile(where: {client_id: {_eq: $client_id}, detail_id: {_eq: $detail_id}}) {
    returning {
      id
    }
  }
}`;


const CHANGE_QUANTITY = gql`mutation ChangeQuantity($client_id: String!, $detail_id: Int!, $quantity: Int!) {
  update_basket_mobile(where: {client_id: {_eq: $client_id}, detail_id: {_eq: $detail_id}}, _set: {quantity: $quantity}) {
    returning {
      id
    }
  }
}`;


const CheckoutPage = () => {

  const { visitorId } = useAuth();

  const [getBasketItems, { data }] = useLazyQuery(GET_BASKET_ITEMS, {
    fetchPolicy: 'network-only',
  });

  const [checkedItems, setCheckedItems] = useState([]);
  const [basketItems, setBasketItems] = useState([]);

  const [checkoutSum, setCheckoutSum] = useState(0);

  const [removeItemFromCart, remove_process] = useMutation(REMOVE_ITEM_FROM_CART);
  const [changeQuantityInCart, change_process] = useMutation(CHANGE_QUANTITY);

  const [state, dispatch] = useContext(StoreContext);


  useEffect(() => {
    if (visitorId) {
      getBasketItems({
        variables: {
          client_id: visitorId,
        }
      })
    }

  }, []);


  useEffect(() => {
    if (data) {
      setBasketItems(data.basket_mobile);
      setCheckedItems(data.basket_mobile.map(item => item.detail_id));
    }
  }, [data]);


  useEffect(() => {
    setCheckoutSum(basketItems.reduce((a, c) => {
      if (checkedItems.indexOf(c.detail_id) !== -1) {
        return a + (c.quantity * c.detail.price_9);
      } else {
        return a
      }
    }, 0));

    dispatch({type: 'SET_CART_COUNT', cart_count: basketItems.length});
  }, [checkedItems, basketItems]);


  const onItemCheck = detail_id => () => {
    if (checkedItems.indexOf(detail_id) !== -1) {
      setCheckedItems(checkedItems.filter(item => item !== detail_id));
    } else {
      setCheckedItems([...checkedItems, ...[detail_id]]);
    }
  }


  const onAllCheck = v => {
    if (v) {
      setCheckedItems(basketItems.map(item => item.detail_id));
    } else {
      setCheckedItems([]);
    }
  }


  const removeItem = detail_id => {
    removeItemFromCart({
      variables: {
        client_id: visitorId,
        detail_id
      },
      refetchQueries: [
        {
          query: GET_BASKET_ITEMS, variables: {
            client_id: visitorId
          }
        },
      ],
    });
  }


  const changeQuantity = (detail_id, quantity) => {
    changeQuantityInCart({
      variables: {
        client_id: visitorId,
        detail_id,
        quantity
      },
      refetchQueries: [
        {
          query: GET_BASKET_ITEMS, variables: {
            client_id: visitorId
          }
        },
      ],
    });
  }


  if (basketItems.length === 0) {
    return (
      <>
        <PageHeader title={'Корзина'} />

        <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description='Нет выбранных товаров'
        />
      </>
    );
  }

  return (
    <>

      <PageHeader title={'Корзина'} />

      <Grid columns={2} style={{margin: '1em'}}>
        <Grid.Item>
          <Checkbox defaultChecked={true} onChange={onAllCheck}>{'Выбрать все'}</Checkbox>
        </Grid.Item>

        <Grid.Item>
          <div className="card-item-price">
            <span className="card-item-price-value">
              {checkoutSum || ''}
            </span>
            <span style={{
              fontSize: '1.5em'
            }}>
              &nbsp;{checkoutSum ? '₽' : ''}
            </span>

          </div>
        </Grid.Item>
      </Grid>

      <div className="card-group" >
        <List>
          {basketItems.map((item, index) => (
            <List.Item key={item.detail_id} style={{background: '#eeeeee'}}>
              <BasketItemCard item={item}
                              checked={checkedItems.indexOf(item.detail_id) !== -1}
                              onCheck={onItemCheck}
                              checkoutSum={checkoutSum}
                              onRemoveItem={removeItem}
                              onChangeQuantity={changeQuantity} />
            </List.Item>
          ))}
        </List>

        {checkoutSum > 0 &&
          <Button color='primary' size='large'>
            {`Оформить ${checkoutSum} руб.`}
          </Button>
        }
      </div>
    </>
  );
}


export default CheckoutPage;
