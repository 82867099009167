import React, {createContext, useReducer} from "react";
import Reducer from './reducer'


const initialState = {
    cart_count: 0,
};


const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <StoreContext.Provider value={[state, dispatch]}>
            {children}
        </StoreContext.Provider>
    )
};


export const StoreContext = createContext(initialState);
export default Store;
