const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CART_COUNT':
        return {
            ...state,
            cart_count: action.cart_count,
        };

    default:
        return state;
  }
};

export default Reducer;
