import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();


const useAuth = () => {
  return useContext(AuthContext);
}


const AuthProvider = ({ children, ...props }) => {
  const auth = ProvideAuth(props);

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}


const ProvideAuth = () => {

  const [visitorId, setVisitorId] = useState('');

  const signin = visitorId => {
    setVisitorId(visitorId)
  };

  return {
    visitorId,
    signin
  }
}

export {
  AuthProvider,
  useAuth,
}
